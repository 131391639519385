<template>
  <v-container class="pa-0" fluid>
    <v-container class="pb-0">
      <!-- header -->
      <header-sheet />
    </v-container>

    <!-- categories -->
    <v-container class="py-12">
      <v-img
        class="mb-6 rounded-lg"
        :src="require(`@/assets/menu/${pagination.page}.png`)"
      />

      <!-- pagination -->
      <v-pagination
        v-model="pagination.page"
        color="primary"
        :length="pagination.pageCount"
        :total-visible="10"
        @input="scrollTop()"
      />
    </v-container>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      pagination: {
        page: 1,
        pageCount: 12,
      },
    };
  },

  methods: {
    scrollTop() {},
  },
};
</script>

<style>
</style>